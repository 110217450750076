<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-input
          v-model="form.keyword"
          placeholder="请输入启动图标题关键词"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input v-model="form.area" placeholder="请输入地区关键词"></el-input>
      </el-form-item>

      <el-form-item label="" prop="">
        <el-select
          v-model="form.type"
          clearable
          style="width: 140px"
          placeholder="动作"
        >
          <el-option
            v-for="(i, idx) in typeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <!-- <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item> -->
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import { guishuSelectList } from '@/api/admin/userManagement'
  export default {
    name: 'AdSumarry',
    components: {},
    data() {
      return {
        time: [],
        form: {
          pageNo: 1,
          pageSize: 10,
        },
        loading: false,
        list: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        typeSelect: [],
        statusSelect: [],
        staffSelect: [],

        columns: [
          {
            prop: '',
            label: '启动图标题',
            width: '',
          },
          {
            prop: '',
            label: '起止时间',
            width: '',
          },
          {
            prop: '',
            label: '浏览人次',
            width: '',
          },
          {
            prop: '',
            label: '跳转人次',
            width: '',
          },
          {
            prop: '',
            label: '到期日期',
            width: '',
          },
          {
            prop: '',
            label: '端口数',
            width: '',
          },
          {
            prop: '',
            label: '网销门店上限',
            width: '',
          },
          {
            prop: '',
            label: '归属',
            width: '',
          },
          {
            prop: '',
            label: '类型',
            width: '',
          },
          {
            prop: '',
            label: '金额',
            width: '',
          },
          {
            prop: '',
            label: '下单日期',
            width: '',
          },
          {
            prop: '',
            label: '状态',
            width: '',
          },
          {
            prop: '',
            label: '时间',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      guishuSelectList().then((r) => {
        this.staffSelect = r.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {},
      handleQuery() {},
      handleExport() {},
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
